import { authFunctionsClient } from '../functions/client';
import { setCurrentAccount } from '../../state/slices/accounts';
import { store } from '../../state/store';
import { ClaimsProductType } from './types/ClaimsProductType';

const getClaimsAccounts = async () => {
  const result = await authFunctionsClient.getClaimsAccounts();

  return result;
};

const _setSelectedAccountToSessionStorage = (account, defaultAccount) => {
  if (account == null && defaultAccount != null) {
    sessionStorage.setItem(
      'selectedAccount',
      defaultAccount.accounts.toString()
    );
  }
};

const getDefaultAccountFromClaims = async claims => {
  // Retrieve accounts products
  const claimsProducts: ClaimsProductType = claims.reduce((acc, curr) => {
    const { products } = curr;

    // eslint-disable-next-line no-param-reassign
    acc = {
      ...acc,
      [curr.accounts]: products,
    };

    return acc;
  }, []);
  // Find the first account with access to "ADELe"
  const accountsWithAccess = Object.entries(claimsProducts).reduce(
    (acc: Array<string>, [key, value]) => {
      if (
        acc.length === 0 &&
        value.find(product => product.name.toUpperCase() === 'ADELE')
      ) {
        acc.push(key);
      }

      return acc;
    },
    []
  );

  const selectedAccount = sessionStorage.getItem('selectedAccount');
  const accountToSelect = selectedAccount || accountsWithAccess[0];
  const defaultAccount = claims.find(
    acc => acc.id.toString() === accountToSelect?.toString()
  );

  _setSelectedAccountToSessionStorage(selectedAccount, defaultAccount);

  if (defaultAccount != null) {
    store.dispatch(setCurrentAccount(defaultAccount));
  }

  return defaultAccount;
};

export { getClaimsAccounts, getDefaultAccountFromClaims };
