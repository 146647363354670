/* eslint-disable import/no-extraneous-dependencies */
import { property } from 'lit/decorators';
import { html, nothing, css } from 'lit';

import '@vaadin/app-layout';
import '@vaadin/tabs';
import '@vaadin/select';
import '@vaadin/icon';
import '@vaadin/icons';
import '@vaadin/side-nav';
import '@vaadin/app-layout/vaadin-drawer-toggle';

import { classMap } from 'lit-html/directives/class-map';

import { PkRouter, navigate } from '@mch/nn-web-viz/dist/packages/router/index';
import { ellipsisVertical } from '@mch/nn-web-viz/packages/icons/ellipsisVertical';
import '@mch/nn-web-viz/dist/nn-icon';
import '@mch/nn-web-viz/dist/nn-badge';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-overlay';
import '@mch/nn-web-viz/dist/apps/empathy-engine/nn-ee-avatar';
import './components/adele-new-thread-overlay';
import './components/adele-help-overlay';
import {
  trackUser,
  trackAccount,
  sendEvent,
  endTracking,
  trackPageView,
  setPreviousPage,
} from '@mch/nn-web-viz/dist/packages/analytics';
import { NNBase } from '@mch/nn-web-viz/dist/packages/base/Base';
import { Route } from '@mch/nn-web-viz/dist/packages/router/types';
// eslint-disable-next-line import/extensions
import { version } from '../package.json';
import { themeStyles } from './theme';
import ThreadService from './service/ThreadService';
import { AdeleShellStyles } from './AdeleShellStyles';
import { getQueryParams, routePrefix } from './utils';
import { AppConfig, Product } from './types';
import { connect, store } from './state/store';
import {
  Account,
  AccountState,
  setCurrentAccount,
} from './state/slices/accounts';
import { getHasuraToken } from './modules/hasura/client';
import { CHAT_BASE_URL } from './constants/endpoints';
import {
  setSelectedThread,
  setThreadItems,
  ThreadState,
} from './state/slices/thread';
import { modelFunctionsClient } from './modules/functions/client';
import {
  setChatModelsLoading,
  setSelectedChatModel,
} from './state/slices/chatModels';
import { TermsPopup } from './TermsPopup';
import './terms-popup';
import { UserState } from './state/slices/user';

const PREFERRED_MODEL_ID = 'ask_studies_v7';

const loadModels = async () => {
  try {
    store.dispatch(setChatModelsLoading(true));

    const models = await modelFunctionsClient.getChatModels(
      store.getState().accounts.currentAccount?.tokens?.chatToken || ''
    );
    const selectedModel =
      models.find(model => model.id === PREFERRED_MODEL_ID) || models[0];

    store.dispatch(setSelectedChatModel(selectedModel));
  } catch (ex) {
    console.error('Error loading chat models', ex);
  } finally {
    store.dispatch(setChatModelsLoading(false));
  }
};

const renderCreateThreadButton = () => html`
  <div slot="invoker">
    <button
      class="new-thread"
      @click=${e => {
        setPreviousPage();
        // This will track the events that will happen on the new thread overlay
        // and will consider everything as a whole new page view event
        sendEvent(e);
        // This will track when the new thread overlay was clicked and will be
        // added on the page where it is clicked
        trackPageView({ page: 'NEW_THREAD_POPUP' });
      }}
    >
      <span class="button__text"> + NEW THREAD </span>
    </button>
  </div>
`;

const newThread = () => html`
  <nn-overlay class="overlay">
    ${renderCreateThreadButton()}
    <adele-new-thread-overlay slot="content"></adele-new-thread-overlay>
  </nn-overlay>
`;

const viewAllThreads = e => {
  sendEvent(e);

  navigate(`${routePrefix}/collections`);

  window.history.replaceState({}, '', `/app/collections`);
};

const renderHelpButton = () => html`
  <div
    nn-analytics-id="help-button"
    nn-analytics-description="Show help popup"
    style="width: 75px;position: absolute; bottom: 8px; right:8px; z-index: 999; "
    slot="invoker"
    @click=${e => sendEvent(e)}
    @keyup=${e => sendEvent(e)}
  >
    <div class="glass-button">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      Help
    </div>
  </div>
`;

// Unuse for now but retaining for future integration with
// new monday help
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const helpOverlay = () => html`
  <nn-overlay class="overlay">
    ${renderHelpButton()}
    <adele-help-overlay slot="content"></adele-help-overlay>
  </nn-overlay>
`;

const renderDefaultSpinner = () => html`...`;

const renderWithoutWrapper = (loading, element) => {
  if (loading) {
    return renderDefaultSpinner();
  }

  return element;
};

const renderFancySpinnerWithWrapper = () => html` <div class="page-wrapper">
  <nn-spinner theme="book"></nn-spinner>
</div>`;

const renderWithWrapper = (loading, element) => {
  if (loading) {
    return renderFancySpinnerWithWrapper();
  }

  return element;
};

class AdeleShell extends connect(store)(NNBase) {
  @property({ type: String }) _title = 'home';

  @property({ type: Object }) _route: Route | undefined;

  @property({ type: String }) _chatToken: string | undefined;

  @property({ type: String, attribute: 'json-string-config' })
  jsonStringConfig: string | undefined;

  @property({ type: Object }) config: AppConfig | undefined;

  @property({ type: Object }) user;

  @property({ type: Array }) _products: Product[] | undefined;

  @property({ type: Boolean }) showAvatarMenuOptions = true;

  @property({ type: Boolean }) showAvatarMenuOptionsButton = true;

  @property({ type: Boolean }) showLogoutOption = true;

  @property({ type: Array }) _allThread: Array<any> = [];

  @property({ type: String }) _selectedThreadId: string = '';

  @property({ type: Boolean }) showClientOptions = false;

  @property({ type: String }) selectedClientId = '';

  @property({ type: String }) selectedClient: string | null = null;

  @property({ type: String }) currentPath = '';

  @property({ type: Object }) _currentAccount: Account | null = null;

  @property({ type: Array }) _accounts: Array<Account> | undefined;

  @property({ type: Boolean }) termsAccepted = false;

  @property({ type: Boolean }) _isAdmin = false;

  @property({ type: Boolean }) _withAccessToAdele: boolean = false;

  @property({ type: Boolean }) _loading: boolean = false;

  @property({ type: Array }) _accountsProducts: Array<any> = [];

  @property({ type: Boolean }) _vanilla: boolean = false;

  private _threadService = new ThreadService();

  static styles = [
    ...themeStyles,
    ...AdeleShellStyles,
    css`
      :host {
        height: 100%;
        display: block;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        overflow: hidden;
        color: var(--nn-body-text-color);
        --vaadin-app-layout-drawer-width: 280px;
        -webkit-overflow-scrolling: none;
      }

      html,
      body {
        overflow: hidden;
      }

      vaadin-app-layout::part(drawer) {
        background-image: none;
        overflow: hidden;
        height: 100%;
        box-sizing: border-box;
      }

      vaadin-tabs {
        padding: 1rem 0 1rem 0;
        height: 100%;
        box-sizing: border-box;
      }

      .nav-actions {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 1rem;
      }

      .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 3.5vw;
      }

      .search > nn-button {
        height: 38px;
        font-weight: normal;
        padding: 0 1.5rem;
      }

      vaadin-app-layout {
        height: 100%;
        --lumo-primary-text-color: #f8f8f8;
        --lumo-primary-color: #f82c91;
      }

      h3 {
        font-weight: 300;
      }

      .page {
        padding: 1rem;
        height: 100%;
        min-height: 720px;
        box-sizing: border-box;
      }

      .navbar-brand-img {
        width: 100%;
        margin-bottom: 1rem;
        margin: 0 auto;
      }

      .navbar-brand {
        padding: 0;
        display: block;
        margin: 0;
      }

      .navbar-brand__wrapper {
        display: flex;
        flex-direction: column;
      }

      .app-version {
        font-size: 0.75rem;
        line-height: 1rem;
      }

      .title {
        font-size: 1rem;
        opacity: 0.5;
      }

      .username {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: normal;
      }

      .userimage {
        width: 48px;
        border-radius: 100%;
      }

      #chat-circle {
        position: fixed;
        bottom: 40px;
        right: 50px;
        background: linear-gradient(90deg, #ff0080 0%, #7928ca 100%);
        width: 65px;
        height: 65px;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        text-align: center;
        z-index: +9999;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6),
          0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }

      .chat-icon {
        width: 30px;
        height: 100%;
        fill: #fff;
        margin: 0 auto;
      }

      .nav-item {
        display: flex;
        width: 200px;
        height: 44px;
        padding: 8px 12px 8px 8px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 6px;
        background: #f8029c;
        margin: 0 auto;
      }

      .nav-icon {
        display: flex;
        padding: 12px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 6px;
        background: var(--primary-600, #cf0082);
      }

      .nav-title {
        color: var(--neutrals-100, #edeef6);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 16px;
      }

      .nav-link-href {
        text-decoration: none;
        margin: 0;
      }

      .nav-user {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: absolute;
        bottom: 16px;
        gap: 1rem;
      }

      .overlay,
      .create__btn {
        width: 100%;
      }

      .options__wrapper {
        position: relative;
        z-index: 99;
      }

      .options {
        position: absolute;
        width: 70px;
        left: 20px;
        bottom: -10px;
        display: flex;
        flex-direction: column;
        background: #242848;
        padding: 8px;
        border-radius: 8px;
      }

      .options__item {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.02em;
        text-align: left;
        padding: 4px 8px;
      }

      .options__item:hover {
        font-weight: 700;
      }

      .new-thread {
        display: flex;
        flex-direction: row;
        background-image: linear-gradient(145deg, #30bafd, #c320ff);
        border-radius: 0.7rem;
        border: none;
        outline: none;
        will-change: transform;
        transform-style: preserve-3d;
        transform-origin: center center;
        transform: scale3d(1, 1, 1);
        cursor: pointer;
        box-shadow: none;
        transition: 0.25s ease all;
        margin-bottom: 1rem;
        width: 100%;
      }

      .get-help {
        width: 50px;
        display: flex;
        padding: 0.2rem;
        flex-direction: column;
        background-image: linear-gradient(145deg, #30bafd, #c320ff);
        border-radius: 0.7rem;
        border: none;
        outline: none;
        will-change: transform;
        transform-style: preserve-3d;
        transform-origin: center center;
        transform: scale3d(1, 1, 1);
        cursor: pointer;
        box-shadow: none;
        transition: 0.25s ease all;
        margin-bottom: 1rem;
      }

      .new-thread:hover .button__text {
        background: transparent;
      }

      .new-thread:focus:active {
        transform: perspective(0.1rem) scale3d(0.98, 0.98, 1)
          translate3d(0, 0.2rem, 0);
      }

      .new-thread .button__text {
        display: flex;
        background: transparent;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: #fff;
        text-transform: uppercase;
        transition: background-image 0.8s ease-in-out;
        padding: 8px 14px;
        width: 100%;
        justify-content: center;
      }

      .new-thread::after {
        content: '';
        z-index: 5;
        position: absolute;
        background-color: #47c2ff;
        right: 4px;
        top: 4px;
        width: 14px;
        height: 14px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        mask-image: url(https://assets.codepen.io/13471/sparkle.svg);
        animation: sparkle1 4.5s ease-in infinite 0s both;
      }

      .new-thread .button__text::before {
        content: '';
        z-index: 5;
        position: absolute;
        background-color: #f8029c;
        right: 18px;
        top: 18px;
        width: 10px;
        height: 10px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        mask-image: url(https://assets.codepen.io/13471/sparkle.svg);
        animation: sparkle1 3.75s ease-in infinite 0s both;
        animation-delay: 1s;
      }

      .new-thread::before {
        content: '';
        z-index: 5;
        position: absolute;
        background-color: #6c00ff;
        right: 2px;
        top: 18px;
        width: 6px;
        height: 6px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        mask-image: url(https://assets.codepen.io/13471/sparkle.svg);
        animation: sparkle1 2.33s ease-in infinite 0s both;
        animation-delay: 0.7s;
      }

      .new-thread .button__text::after {
        content: '';
        z-index: 5;
        position: absolute;
        background-color: #6c00ff;
        right: 23px;
        top: 4px;
        width: 7px;
        height: 7px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        mask-image: url(https://assets.codepen.io/13471/sparkle.svg);
        animation: sparkle1 3.75s ease-in infinite 0s both;
        animation-delay: 0.7s;
      }

      @keyframes sparkle1 {
        0% {
          opacity: 0;
          translate: -5px 0px;
          scale: 0.85;
          animation-timing-function: ease-in;
        }
        25% {
          opacity: 1;
          scale: 1;
          animation-timing-function: ease-out;
        }
        33%,
        49.999% {
          animation-timing-function: ease-out;
          opacity: 0;
          translate: 0px -20px;
          scale: 0.75;
        }
        50% {
          opacity: 0;
          translate: -15px 0px;
          scale: 0.85;
          animation-timing-function: ease-in;
        }
        75% {
          opacity: 1;
          scale: 1;
          animation-timing-function: ease-out;
        }
        83%,
        100% {
          animation-timing-function: ease-out;
          opacity: 0;
          translate: -10px -20px;
          scale: 0.75;
        }
      }

      .options__item:hover {
        background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
      }

      vaadin-side-nav-item > vaadin-icon {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 12px;
        width: 48px;
        height: 48px;
        background-position: center;
        fill: #fff;
        text-align: center;
        transition: all 0.3s ease;
      }

      vaadin-side-nav-item > .vaadin-icon {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 12px;
        width: 24px;
        height: 24px;
        background-position: center;
        fill: #fff;
        text-align: center;
        vertical-align: middle;
      }

      vaadin-tabs[orientation='vertical']::part(tabs) {
        width: 100%;
        margin: 0;
      }

      vaadin-side-nav-item > .vaadin-icon-submenu {
        padding: 10px;
        width: 24px;
        height: 24px;
        background-position: center;
        fill: #fff;
        text-align: center;
        vertical-align: middle;
      }

      vaadin-side-nav-item.active::part(content) > .vaadin-icon {
        background: transparent;
        border-radius: 10px;
        padding: 12px;
        width: 24px;
        height: 24px;
        background-position: center;
        fill: #fff;
        text-align: center;
        vertical-align: middle;
      }

      vaadin-side-nav-item > .vaadin-icon > svg {
        width: 24px;
        height: 24px;
        text-align: center;
        vertical-align: middle;
      }

      vaadin-side-nav-item {
        font-size: 14px;
        font-weight: 400;
      }

      vaadin-side-nav-item::part(content) {
        background: #0000;
        border-radius: 8px;
        padding-right: 4px;
      }

      vaadin-side-nav-item::part(content):hover {
        background: #00000000;
        transition: all 0.3s ease;
      }

      vaadin-side-nav-item.active::part(content) {
        background: #ffffff30;
        border-radius: 10px;
      }

      vaadin-side-nav-item::part(content)
        vaadin-side-nav-item::part(toggle-button) {
        display: none;
      }

      .active {
        font-weight: 800;
        color: white;
      }

      .navbar-text-brand {
        color: #fff;
        text-align: left;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-decoration: none;
        margin: 0px 20px 20px 20px;
      }

      .nav-menu {
        display: flex;
        padding: 24px 18px;
        flex-direction: column;
        gap: 4px;
        border-radius: 30px;
        background: rgba(28, 31, 40, 0.5);
        backdrop-filter: blur(18px);
        overflow-x: hidden;
        height: 100%;
        box-sizing: border-box;
      }

      .nav-menu::-webkit-scrollbar {
        width: 10px;
        margin-top: 10px;
      }

      .glass-button {
        position: relative;
        padding: 10px 10px;
        text-decoration: none;
        color: #fff;
        font-optical-sizing: 2em;
        text-transform: uppercase;
        font-family: sans-serif;
        letter-spacing: 4px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        border-radius: 10px;
      }

      .glass-button::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
      }

      .glass-button::after {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.4),
          transparent
        );
        transition: 0.5s;
        transition-delay: 0.5s;
      }

      .glass-button:hover::after {
        left: 100%;
      }

      .glass-button span {
        position: absolute;
        display: block;
        transition: 0.5s ease;
        border-radius: 10px;
      }

      .glass-button span:nth-child(1) {
        top: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: #fff;
      }

      .glass-button:hover span:nth-child(1) {
        width: 100%;
        transform: translateX(100%);
      }

      .glass-button span:nth-child(3) {
        bottom: 0;
        right: 0;
        width: 0;
        height: 1px;
        background: #fff;
      }

      .glass-button:hover span:nth-child(3) {
        width: 100%;
        transform: translateX(-100%);
      }

      .glass-button span:nth-child(2) {
        top: 0;
        left: 0;
        width: 1px;
        height: 0;
        background: #fff;
      }

      .glass-button:hover span:nth-child(2) {
        height: 100%;
        transform: translateY(100%);
      }

      .glass-button span:nth-child(4) {
        bottom: 0;
        right: 0;
        width: 1px;
        height: 0;
        background: #fff;
      }

      .glass-button:hover span:nth-child(4) {
        height: 100%;
        transform: translateY(-100%);
      }

      .page-wrapper {
        border-radius: 24px;

        background: linear-gradient(
          237deg,
          rgba(28, 31, 40, 0.1) 5.65%,
          rgba(28, 31, 40, 0.3) 85.87%,
          rgba(28, 31, 40, 0.5) 97.63%
        );
        box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(18px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 10px;

        font-size: 2rem;
        color: #fff;
      }
    `,
  ];

  declare _router: PkRouter;

  private _endTracking = () => {
    endTracking();
  };

  constructor() {
    super();

    this.showAvatarMenuOptions = false;

    window.addEventListener('vaadin-router-location-changed', () => {
      this._selectParentCurrentVaadinSideNavItem();
    });

    this._init();
    this.watchScreenSize();
    loadModels();
    this._loadThreads();
    this._selectedThreadId = getQueryParams().get('threadId') || '';
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback();

    getHasuraToken().then(token => {
      this._chatToken = token;
    });
    this._selectParentCurrentVaadinSideNavItem();
    this.updateCurrentPath();
    window.addEventListener(
      window.location.pathname,
      // eslint-disable-next-line wc/require-listener-teardown
      this.updateCurrentPath.bind(this)
    );

    if (!this.termsAccepted) {
      this.termsAccepted =
        store.getState().accounts.currentAccount?.agreedToTerms || false;
    }
    window.addEventListener('beforeunload', this._endTracking);
  }

  disconnectedCallback(): void {
    window.removeEventListener('beforeunload', this._endTracking);
  }

  updateCurrentPath(): void {
    // Update the current path based on your routing logic
    this.currentPath = window.location.pathname;
  }

  stateChanged(_state: {
    accounts: AccountState;
    thread: ThreadState;
    user: UserState;
  }): void {
    this.user = _state.user.value;

    this._allThread = _state.thread.items;
    this._accounts = _state.accounts.accounts;
    this._accountsProducts = _state.accounts.currentAccount?.products || [];
    this._vanilla = _state.accounts.currentAccount?.vanilla || false;

    this._withAccessToAdele = this._accountsProducts
      .map(product => product.name.toUpperCase())
      .includes('ADELE');

    if (!this.termsAccepted) {
      this.termsAccepted =
        _state.accounts.currentAccount?.agreedToTerms || false;
    }

    if (this._currentAccount?.id !== _state.accounts.currentAccount?.id) {
      this._currentAccount = _state.accounts.currentAccount;

      if (this._currentAccount == null) return;

      this.selectClient(this._currentAccount.id);
    }

    this._isAdmin = _state.accounts.currentAccount?.isAdmin || false;

    // Analytics
    trackUser({
      id: _state.accounts.currentAccount?.userId || 0,
      name: this.user?.nickname,
    });

    if (this._currentAccount != null) {
      trackAccount(this._currentAccount);
    }

    if (_state.thread.selected) {
      this._selectedThreadId = this._allThread.find(
        t => t.id === _state.thread.selected
      )?.id;
    }
  }

  async _loadThreads() {
    const result = await this._threadService.getSortedThreads();

    this._allThread = result;

    store.dispatch(setThreadItems(result));
  }

  async _init() {
    this._initRouter();
  }

  _getPathForExternalProduct(path) {
    const PRODUCT_PATH_MAP = {
      chat: `${CHAT_BASE_URL}/?chatToken=${this._chatToken}`,
    };
    return PRODUCT_PATH_MAP[path];
  }

  _initRouter() {
    const config = {
      routes: [
        {
          path: `${routePrefix}/home`,
          render: () => html` <adele-home></adele-home>`,
          import: () => import('./adele-home'),
        },
        {
          path: `${routePrefix}/frequent`,
          render: () => html` <adele-frequent></adele-frequent>`,
          import: () => import('./adele-frequent'),
        },
        {
          path: `${routePrefix}/collections`,
          render: () => html` <adele-collections></adele-collections>`,
          import: () => import('./adele-collections'),
        },
        {
          path: `${routePrefix}/threads`,
          render: () => html` <adele-home></adele-home>`,
          import: () => import('./adele-home'),
        },
        {
          path: `${routePrefix}/manage`,
          render: () => html` <adele-manage></adele-manage>`,
          import: () => import('./adele-manage'),
        },
        {
          path: '*',
          redirect: () => `${routePrefix}/home`,
        },
      ],
    };
    this._router = new PkRouter(config, newRoute => {
      this._route = newRoute;
      this.updateCurrentPath();
    });
  }

  updated(cp: Map<string | number | symbol, unknown>) {
    super.updated(cp);

    if (cp.has('jsonStringConfig') && this.jsonStringConfig != null) {
      this.config = JSON.parse(this.jsonStringConfig);
    }

    if (!this.termsAccepted) {
      const termsPopup =
        this.shadowRoot?.querySelector<TermsPopup>('terms-popup');
      if (termsPopup) {
        termsPopup.visible = true;
      }
    }
  }

  _toggleOptions() {
    this.showAvatarMenuOptions = !this.showAvatarMenuOptions;
  }

  _renderLogoutOption() {
    return this.showLogoutOption
      ? html`
          <div
            class="options__item"
            @click=${this._logout}
            @keyup=${this._logout}
          >
            Logout
          </div>
        `
      : nothing;
  }

  // render logout ellipsis
  _renderThreeDots() {
    if (!this.showAvatarMenuOptionsButton) return nothing;

    const icon: any = ellipsisVertical;

    return html`
      <div class="options__wrapper">
        <nn-icon .svg=${icon} @click=${this._toggleOptions}></nn-icon>
        ${this.showAvatarMenuOptions
          ? html` <div class="options">${this._renderLogoutOption()}</div>`
          : nothing}
      </div>
    `;
  }

  async _logout(e) {
    sendEvent(e);

    this.dispatchEvent(new CustomEvent('logout'));
    localStorage.clear();
  }

  async _login() {
    this.dispatchEvent(new CustomEvent('login'));
  }

  _selectParentCurrentVaadinSideNavItem() {
    const currentVaadinSideNavItem = this.shadowRoot?.querySelector(
      'vaadin-side-nav-item[current]'
    );

    if (currentVaadinSideNavItem) {
      const parent = currentVaadinSideNavItem.closest(
        'vaadin-side-nav-item:not([current])'
      );
      if (parent) {
        parent.setAttribute('current', '');
      }
    }
  }

  isAnyChildCurrent() {
    const currentPath = window.location.pathname;
    return (this._products || [])
      .filter(p => !p.suitable_for_project_creation)
      .some(p => `${routePrefix}/${p.path}` === currentPath);
  }

  _setSelectedThread(e, value) {
    sendEvent(e);

    navigate(`${routePrefix}/threads`);

    store.dispatch(setSelectedThread(value));

    window.history.replaceState({}, '', `/app/threads?threadId=${value}`);

    this._selectedThreadId = value;
  }

  toggleClientOptions(): void {
    this.showClientOptions = !this.showClientOptions;
    this.requestUpdate(); // Ensure the component re-renders to reflect the state change
  }

  async selectClient(id: number): Promise<void> {
    sessionStorage.setItem('selectedAccount', id.toString());

    const selectedAccount = this._accounts?.find(account => account.id === id);

    if (id === this._currentAccount?.id) return;

    try {
      this._loading = true;
      this.selectedClient = selectedAccount?.name || '';

      store.dispatch(setCurrentAccount(selectedAccount));

      this.showClientOptions = false;
    } catch (error) {
      throw new Error(`Error selecting client: ${error}`);
    } finally {
      this._loading = false;
    }
  }

  updateStyleHover(name: string, isHovering: boolean): void {
    // Retrieve all client option elements
    const elements = this.shadowRoot?.querySelectorAll('.client-option');
    elements?.forEach(el => {
      // Check if the element's text matches the client name
      if (el.textContent?.trim() === name) {
        const elementWithStyle = el as HTMLElement; // Cast the element to HTMLElement
        if (isHovering) {
          elementWithStyle.style.backgroundColor = 'rgba(0, 0, 0, 0.4)'; // Darker on hover
          elementWithStyle.style.borderRadius = '12px';
        } else {
          // Reset to default style when not hovering
          elementWithStyle.style.backgroundColor =
            this.selectedClient === name ? 'rgba(0, 0, 0, 0.2)' : 'transparent';
          elementWithStyle.style.borderRadius =
            this.selectedClient === name ? '12px' : '0';
        }
      }
    });
  }

  private renderClientOptions() {
    return html`
      <div class="client-options">
        ${(store.getState().accounts.accounts || []).map(
          client => html`
            <div
              @click="${() => this.selectClient(client.id)}"
              @keyup="${() => this.selectClient(client.id)}"
              @focus="${() => this.selectClient(client.id)}"
              @blur="${() => this.selectClient(client.id)}"
              style="
padding:8px;gap:8px;display:flex;align-items:center;flex-wrap:nowrap;cursor:pointer;${this
                .selectedClient === client.name
                ? 'background-color:rgba(255, 255, 255, 0.25);;border-radius:12px;'
                : ''}
                 transition: background-color 0.3s;"
              @mouseover="${() => this.updateStyleHover(client.name, true)}"
              @mouseout="${() => this.updateStyleHover(client.name, false)}"
            >
              <span style="font-size:16px;font-weight:700;color:#ffffff;"
                >${client.name}</span
              >
            </div>
          `
        )}
        ${this._isAdmin
          ? html`
              <div
                @click=${this._manageAccountsClicked}
                @keyup=${this._manageAccountsClicked}
                style="padding:8px;gap:8px;display:flex;align-items:center;flex-wrap:nowrap;cursor:pointer;$
                 transition: background-color 0.3s;"
              >
                <span
                  style="font-size:16px;font-weight:700;color:#f8029c;margin-top:1rem;"
                  >Manage Accounts</span
                >
              </div>
            `
          : nothing}
      </div>
    `;
  }

  _manageAccountsClicked() {
    this.showClientOptions = false;
    navigate(`${routePrefix}/manage`);
  }

  private _onTermsAccepted() {
    this.termsAccepted = true;
  }

  _renderFrequentSidebar() {
    if (this._vanilla) return nothing;

    return html`
      <vaadin-side-nav-item
        @click=${() => {
          navigate(`${routePrefix}/frequent`);
        }}
        class="${classMap({
          active: this.currentPath === `${routePrefix}/frequent`,
        })}"
      >
        <div class="vaadin-icon" slot="prefix">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_176_462)">
              <path
                d="M17.25 9.75C17.25 7.76088 16.4598 5.85322 15.0533 4.4467C13.6468 3.04018 11.7391 2.25 9.75 2.25C7.76088 2.25 5.85322 3.04018 4.4467 4.4467C3.04018 5.85322 2.25 7.76088 2.25 9.75C2.25 11.7391 3.04018 13.6468 4.4467 15.0533C5.85322 16.4598 7.76088 17.25 9.75 17.25C11.7391 17.25 13.6468 16.4598 15.0533 15.0533C16.4598 13.6468 17.25 11.7391 17.25 9.75ZM15.8016 17.3953C14.1422 18.7125 12.0375 19.5 9.75 19.5C4.36406 19.5 0 15.1359 0 9.75C0 4.36406 4.36406 0 9.75 0C15.1359 0 19.5 4.36406 19.5 9.75C19.5 12.0375 18.7125 14.1422 17.3953 15.8016L23.6719 22.0781C24.1125 22.5187 24.1125 23.2312 23.6719 23.6672C23.2313 24.1031 22.5188 24.1078 22.0828 23.6672L15.8016 17.3953Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_176_462">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        Frequent Topics
      </vaadin-side-nav-item>
    `;
  }

  _renderCollectionsSidebar() {
    if (this._vanilla) return nothing;

    return html`
      <vaadin-side-nav-item
        @click=${() => {
          navigate(`${routePrefix}/collections`);
        }}
        class="${classMap({
          active: this.currentPath === `${routePrefix}/collections`,
        })}"
      >
        <div class="vaadin-icon" slot="prefix">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_176_468)">
              <path
                d="M21.75 10.5C21.75 10.0875 21.4125 9.75 21 9.75H3C2.5875 9.75 2.25 10.0875 2.25 10.5V21C2.25 21.4125 2.5875 21.75 3 21.75H21C21.4125 21.75 21.75 21.4125 21.75 21V10.5ZM21 7.5C22.6547 7.5 24 8.84531 24 10.5V21C24 22.6547 22.6547 24 21 24H3C1.34531 24 0 22.6547 0 21V10.5C0 8.84531 1.34531 7.5 3 7.5H21ZM20.625 3.75C21.2484 3.75 21.75 4.25156 21.75 4.875C21.75 5.49844 21.2484 6 20.625 6H3.375C2.75156 6 2.25 5.49844 2.25 4.875C2.25 4.25156 2.75156 3.75 3.375 3.75H20.625ZM18.375 0C18.9984 0 19.5 0.501562 19.5 1.125C19.5 1.74844 18.9984 2.25 18.375 2.25H5.625C5.00156 2.25 4.5 1.74844 4.5 1.125C4.5 0.501562 5.00156 0 5.625 0H18.375Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_176_468">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        Collections
      </vaadin-side-nav-item>
    `;
  }

  _renderTerms() {
    if (this.termsAccepted) return nothing;

    return html`
      <terms-popup @accepted="${this._onTermsAccepted}"></terms-popup>
    `;
  }

  render() {
    return html`
      ${this._renderTerms()}

      <vaadin-app-layout primary-section="drawer">
        <vaadin-tabs
          orientation="vertical"
          slot="drawer"
          theme="hide-scroll-buttons"
        >
          <vaadin-side-nav class="nav-menu">
            <div class="nav__top">
              <a
                nn-analytics-id="home-button"
                nn-analytics-description="Navigate to home"
                @click=${e => sendEvent(e)}
                class="navbar-brand navbar-text-brand"
                href="/"
              >
                <div class="navbar-brand__wrapper">
                  ADELe
                  <span class="app-version">v${version}</span>
                </div>
              </a>
              <div class="">${newThread()}</div>
              <!-- home section -->

              <vaadin-side-nav-item
                class="${classMap({
                  active: this.currentPath === `${routePrefix}/home`,
                })}"
                @click=${() => {
                  navigate(`${routePrefix}/home`);
                }}
              >
                <div class="vaadin-icon" slot="prefix">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="house-regular 1" clip-path="url(#clip0_176_456)">
                      <path
                        id="Vector"
                        d="M11.3541 0.267187C11.7291 -0.0890625 12.275 -0.0890625 12.65 0.267187L23.65 10.7672C24.0708 11.1703 24.125 11.8781 23.7666 12.3516C23.4083 12.825 22.7791 12.8812 22.3583 12.4828L21.3333 11.5078V20.25C21.3333 22.3219 19.8416 24 18 24H5.99998C4.15831 24 2.66664 22.3219 2.66664 20.25V11.5078L1.64581 12.4828C1.22498 12.8859 0.591644 12.825 0.237478 12.3516C-0.116689 11.8781 -0.0666889 11.1656 0.354144 10.7672L11.3541 0.267187ZM12 2.60156L4.66664 9.6V20.25C4.66664 21.0797 5.26248 21.75 5.99998 21.75H7.99998V14.625C7.99998 13.5891 8.74581 12.75 9.66664 12.75H14.3333C15.2541 12.75 16 13.5891 16 14.625V21.75H18C18.7375 21.75 19.3333 21.0797 19.3333 20.25V9.6L12 2.60156ZM9.99998 21.75H14V15H9.99998V21.75Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_176_456">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                Home
              </vaadin-side-nav-item>
              ${this._renderFrequentSidebar()}
              ${this._renderCollectionsSidebar()}

              <vaadin-side-nav-item
                expanded
                class="threads-scroll ${classMap({
                  active: this.currentPath === `${routePrefix}/threads`,
                })}"
              >
                <div class="vaadin-icon" slot="prefix">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_176_474)">
                      <path
                        d="M5.79372 18.3422C6.39841 17.9016 7.18122 17.7891 7.88435 18.0422C9.12654 18.4922 10.5187 18.75 12 18.75C17.8453 18.75 21.75 14.9766 21.75 11.25C21.75 7.52344 17.8453 3.75 12 3.75C6.15466 3.75 2.24997 7.52344 2.24997 11.25C2.24997 12.75 2.83122 14.1938 3.92341 15.4313C4.32654 15.8859 4.52341 16.4859 4.47654 17.0953C4.41091 17.9438 4.20935 18.7219 3.94685 19.4109C4.74372 19.0406 5.40466 18.6281 5.79372 18.3469V18.3422ZM0.993724 20.2453C1.0781 20.1187 1.15779 19.9922 1.23279 19.8656C1.70154 19.0875 2.14685 18.0656 2.23591 16.9172C0.829662 15.3187 -2.59331e-05 13.3641 -2.59331e-05 11.25C-2.59331e-05 5.86406 5.37185 1.5 12 1.5C18.6281 1.5 24 5.86406 24 11.25C24 16.6359 18.6281 21 12 21C10.2609 21 8.61091 20.7 7.12029 20.1609C6.56247 20.5688 5.6531 21.1266 4.57497 21.5953C3.86716 21.9047 3.06091 22.1859 2.22654 22.35C2.18904 22.3594 2.15154 22.3641 2.11404 22.3734C1.90779 22.4109 1.70622 22.4438 1.49529 22.4625C1.48591 22.4625 1.47185 22.4672 1.46247 22.4672C1.22341 22.4906 0.984349 22.5047 0.745287 22.5047C0.440599 22.5047 0.168724 22.3219 0.0515366 22.0406C-0.0656509 21.7594 -2.59429e-05 21.4406 0.210912 21.225C0.403099 21.0281 0.576537 20.8172 0.740599 20.5922C0.820287 20.4844 0.895287 20.3766 0.965599 20.2687C0.970287 20.2594 0.974974 20.2547 0.979662 20.2453H0.993724Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_176_474">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                Threads
                ${renderWithoutWrapper(
                  this._loading,
                  this._allThread.slice(0, 3).map((thread, index) => {
                    const title = thread?.name;
                    const shortTitle =
                      title?.length > 25
                        ? `${title.substring(0, 25)}...`
                        : title;

                    if (!this._withAccessToAdele) {
                      return nothing;
                    }

                    return html` <vaadin-side-nav-item
                      class="${classMap({
                        active:
                          this.currentPath === `${routePrefix}/threads` &&
                          thread.id === this._selectedThreadId,
                      })}"
                      nn-analytics-id="thread-link"
                      nn-analytics-description="Navigate to a specific thread"
                      @click=${e => {
                        this._setSelectedThread(e, thread.id);
                      }}
                      slot="children"
                      style="margin-top: ${index === 0 ? '10px' : '0'}"
                    >
                      ${shortTitle}
                    </vaadin-side-nav-item>`;
                  })
                )}
              </vaadin-side-nav-item>
              ${this._allThread.length > 3
                ? html`
                    <vaadin-side-nav-item
                      nn-analytics-id="show-all-threads"
                      nn-analytics-description="Navigate to all threads"
                      @click=${viewAllThreads}
                      style="color: #F82C91;margin-left:24px;"
                    >
                      Show All Threads
                    </vaadin-side-nav-item>
                  `
                : nothing}
            </div>
            <div class="nav__bottom">${this._renderAccountSelector()}</div>
          </vaadin-side-nav>
        </vaadin-tabs>

        <div class="page">
          ${renderWithWrapper(
            this._loading,
            this._withAccessToAdele
              ? html`
                  <!--helpOverlay()-->
                  ${this._route?.render &&
                  this._route.render(this._route.props, this._route.query)}
                `
              : html` <div class="page-wrapper">
                  You account does not have access to this application.
                </div>`
          )}
        </div>
      </vaadin-app-layout>
    `;
  }

  _renderAccountSelector() {
    return html` <div class="nav-user-details">
      ${this.showClientOptions ? this.renderClientOptions() : null}
      <vaadin-side-nav-item>
        <div
          style="display:flex;width:100%;height:57px;justify-content:space-between;align-items:center;flex-wrap:nowrap;position:relative;margin:0 auto; gap:0.25rem;"
          class="main-container"
        >
          <div
            style="display:flex;height:100%;width:100%;gap:8px;align-items:center;flex-shrink:0;flex-wrap:wrap;position:relative;overflow:hidden;flex:1;"
          >
            <span
              style="display:flex;width:100%;height:14px;justify-content:flex-start;align-items:center;flex-basis:auto;font-size:16px;font-weight:400;line-height:14px;color:#ffffff;position:relative;text-align:left;white-space:nowrap;z-index:1"
              >Team</span
            ><span
              style="min-width:0;height:29px;flex-grow:1;flex-basis:auto;font-size:20px;font-weight:900;line-height:29px;color:#ffffff;position:relative;text-align:left;white-space:nowrap;z-index:2;"
              >${this.selectedClient || this._currentAccount?.name}</span
            >
          </div>
          <div
            style="width:24px;height:24px;flex-shrink:0;position:relative;overflow:hidden;z-index:3;cursor:pointer;"
            @click="${this.toggleClientOptions}"
            @keyup="${this.toggleClientOptions}"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 16.5C13.3284 16.5 14 17.1719 14 18C14 18.8281 13.3284 19.5 12.5 19.5C11.6716 19.5 11 18.8281 11 18C11 17.1719 11.6716 16.5 12.5 16.5ZM12.5 11.5C13.3284 11.5 14 12.1719 14 13C14 13.8281 13.3284 14.5 12.5 14.5C11.6716 14.5 11 13.8281 11 13C11 12.1719 11.6716 11.5 12.5 11.5ZM12.5 9.5C11.6716 9.5 11 8.82812 11 8C11 7.17156 11.6716 6.5 12.5 6.5C13.3284 6.5 14 7.17156 14 8C14 8.82812 13.3284 9.5 12.5 9.5Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </vaadin-side-nav-item>
      <vaadin-side-nav-item>
        <div class="" slot="prefix">
          <img
            class="userimage"
            src=${this.user?.picture ||
            'https://media.northify.net/icons/hate-audit-192x192.png'}
            alt="${this.user?.nickname}"
          />
        </div>
        <div class="username" ?hidden=${this.screen.isMobile}>
          ${this.user?.nickname ? this.user.nickname : 'Login'}
        </div>
        <vaadin-side-nav-item slot="children" @click=${this._logout}>
          ${this.user ? 'Logout' : 'Login'}
        </vaadin-side-nav-item>
      </vaadin-side-nav-item>
    </div>`;
  }

  navigate(path: string): void {
    this.currentPath = path;
    window.history.pushState({}, '', path);
    this.requestUpdate();
  }
}

export { AdeleShell };
